import 'bootstrap'
import $ from 'jquery'
window.$ = window.jQuery = $
import 'jquery.easing'
import './theme'
import './theme.init'
import './common'

document.addEventListener('DOMContentLoaded', function() {
    $('[data-bs-toggle="popover"]').popover()
});
